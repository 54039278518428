import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {en_GB, fr_FR, en_US, NzI18nService} from 'ng-zorro-antd/i18n';
export interface Locale {
    lang: string;
    data: any;
}

export class TranslationSettings {
    static TRANSLATION_SETTINGS = {};
}

@Injectable({
    providedIn: 'root'
})
export class FuseTranslationLoaderService {
    constructor(
        private _translateService: TranslateService,
        private _NzI18nService: NzI18nService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load translations
     *
     * @param {Locale} args
     */
    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        switch (this._translateService.currentLang) {
            case 'en':
                this._NzI18nService.setLocale(en_GB);
                break;
            case 'us':
                this._NzI18nService.setLocale(en_US);
                break;
            case 'fr':
                this._NzI18nService.setLocale(fr_FR);
                break;
            case 'in':
                this._NzI18nService.setLocale(en_GB);
                break;
            default:
                this._NzI18nService.setLocale(en_GB);
        }

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this._translateService.setTranslation(
                locale.lang,
                this.recursiveReplacement(locale.data, TranslationSettings.TRANSLATION_SETTINGS),
                true
            );
        });
    }

    linearReplacement(data): string {
        for (const key of Object.keys(TranslationSettings.TRANSLATION_SETTINGS)) {
            data = data.replace(key, TranslationSettings.TRANSLATION_SETTINGS[key]);
        }
        return data;
    }

    recursiveReplacement(data, keymap) {
        for (const key of Object.keys(data)) {
            if (data[key] instanceof Object) {
                data[key] = this.recursiveReplacement(data[key], keymap);
            } else if (keymap[data[key]]) {
                data[key] = keymap[data[key]];
            }
        }
        return data;
    }
}
